<script>
import { defineComponent } from 'vue'

import telegramLogo from "@/components/UI-kit/logos/telegram-logo.vue";
import whatsappLogo from "@/components/UI-kit/logos/whatsapp-logo.vue";

import { openWhatsapp } from "@/components/functions/openWhatsapp/openWhatsapp";

export default defineComponent({
  name: "kosmos-main-contacts",

  components: {
    telegramLogo,
    whatsappLogo,
  },

  data() {
    return {
      settings: {
        apiKey: 'cb42ebe3-f9f5-4c76-94e0-4ac8742524e1',
        lang: 'ru_RU',
        coordorder: 'latlong',
        enterprise: false,
        version: '2.1'
      },

      markerIcon: {
        layout: 'default#imageWithContent',
        imageHref: '/assets/images/svg-icons/logo.svg',
        imageSize: [74, 40],
        imageOffset: [-37, -20],
      },

      openWhatsapp,
    }
  },
})
</script>

<template>
  <div class="kosmos-main-contacts">
    <h2 class="title">Контакты</h2>

    <main>
      <nav>
        <h1 class="active">Москва</h1>

        <h1>Санкт-Петербург</h1>
      </nav>

      <div class="contacts">
        <div class="addresses-container">
          <div class="address">
            <h2 class="title">Адрес</h2>

            <div class="content">
              <h1 class="title">г. Москва, Проспект мира, д. 104</h1>

              <div class="description">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
                  <g clip-path="url(#clip0_405_82)">
                    <path d="M13.8363 10.375L11.7687 5.15833L10.0254 8.20833L8.28208 5.15833L6.21458 10.3767H5.61708V11.1608H8.73042V10.3767H8.26958L8.71792 9.08083L10.0254 11.2225L11.3329 9.08083L11.7812 10.3767H11.3204V11.1608H14.4462V10.3767L13.8363 10.375ZM18.2821 8.78167C18.2826 7.69395 18.0688 6.61679 17.6528 5.61176C17.2368 4.60674 16.6268 3.69356 15.8577 2.92442C15.0885 2.15529 14.1753 1.54529 13.1703 1.12929C12.1653 0.713291 11.0881 0.499453 10.0004 0.500001C5.43042 0.500001 1.71875 4.21083 1.71875 8.78167C1.71875 11.0733 2.65292 13.14 4.14708 14.6342C4.24065 14.7286 4.36017 14.793 4.49045 14.8193C4.62073 14.8455 4.75589 14.8325 4.87872 14.7817C5.00156 14.731 5.10653 14.6449 5.18028 14.5343C5.25404 14.4237 5.29324 14.2937 5.29292 14.1608C5.2932 13.9792 5.2216 13.8048 5.09375 13.6758C3.7994 12.3762 3.07404 10.6159 3.07708 8.78167C3.07708 4.95833 6.17708 1.845 10.0004 1.845C13.8237 1.845 16.9371 4.95833 16.9371 8.78167C16.9391 9.69301 16.7608 10.5957 16.4124 11.4379C16.064 12.28 15.5524 13.0448 14.9071 13.6883L9.05375 19.5408L10.0004 20.5L15.8662 14.6342C16.6348 13.866 17.2439 12.9535 17.6585 11.9491C18.0731 10.9447 18.285 9.86827 18.2821 8.78167Z" fill="#EDA20A"/>
                  </g>
                  <defs>
                    <clipPath id="clip0_405_82">
                      <rect width="20" height="20" fill="white" transform="translate(0 0.5)"/>
                    </clipPath>
                  </defs>
                </svg>

                <h1 class="title">Алексеевская</h1>
              </div>
            </div>
          </div>

          <div class="address">
            <h2 class="title">Электронная почта</h2>

            <h1 class="mail"><a href="mailto:doktorkosmos@yandex.ru">doktorkosmos@yandex.ru</a></h1>
          </div>

          <div class="address">
            <h2 class="title">Телефон</h2>

            <h1 class="tel"><a href="tel:+79687006129">+7 (968) 700 61-29</a></h1>
          </div>

          <div class="logo-container">
            <a href="https://t.me/Dr_KosMedMos" target="_blank"><telegram-logo /></a>

            <whatsapp-logo @click="openWhatsapp" />
          </div>
        </div>

        <div class="map">
          <yandex-map
              :settings="settings"
              :coords="[55.802153, 37.636886]"
              :zoom="16"
              :ymap-class="'ymap'"
              :controls="[]"
          >
            <ymap-marker
                marker-id="flash-main-contacts-ymapmarker-1"
                :coords="[55.802153, 37.636886]"
                :icon="markerIcon"
            >
            </ymap-marker>
          </yandex-map>
        </div>
      </div>
    </main>
  </div>
</template>

<style scoped lang="sass">
.kosmos-main-contacts
  display: flex
  flex-direction: column
  background-color: $green

  @media (min-width: $desktopScreenMinWidth)
    padding: 100px 112px
    gap: 60px

  @media (max-width: $mobileScreenMaxWidth)
    padding-top: 70px
    gap: 30px

  > .title
    font-weight: 700
    line-height: 120%
    text-align: center

    @media (min-width: $desktopScreenMinWidth)
      font-size: 40px

    @media (max-width: $mobileScreenMaxWidth)
      font-size: 24px

  main
    display: flex
    flex-direction: column
    gap: 30px
    align-items: flex-start

    nav
      display: flex
      gap: 16px

      @media (min-width: $desktopScreenMinWidth)

      @media (max-width: $mobileScreenMaxWidth)
        width: 100%
        justify-content: center
        padding: 0 20px

      > *
        font-weight: 400
        line-height: 100%
        padding: 12px 20px 14px
        color: $violet
        +border-radius(999px)
        border: 1px solid $violet
        +user-select(none)

        @media (min-width: $desktopScreenMinWidth)
          font-size: 20px

        @media (max-width: $mobileScreenMaxWidth)
          font-size: 18px

        &:hover
          cursor: pointer

        &.active
          background-color: $violet
          color: $white

    .contacts
      display: flex

      @media (min-width: $desktopScreenMinWidth)
        gap: 20px
        width: 100%

      @media (max-width: $mobileScreenMaxWidth)
        gap: 2px
        flex-direction: column
        width: 100%

      .addresses-container
        display: flex
        flex-direction: column
        background-color: white

        @media (min-width: $desktopScreenMinWidth)
          gap: 30px
          width: 100%
          flex: 1
          +border-radius(40px)
          padding: 32px 30px

        @media (max-width: $mobileScreenMaxWidth)
          gap: 24px
          align-items: center
          +border-radius(20px)
          padding: 30px 10px
          max-width: 100%
          margin: 0 20px

        .address
          display: flex
          flex-direction: column

          @media (min-width: $desktopScreenMinWidth)
            gap: 24px

          @media (max-width: $mobileScreenMaxWidth)
            gap: 16px

          > .title
            font-weight: 700
            line-height: 19px
            color: #6C8899

            @media (min-width: $desktopScreenMinWidth)
              font-size: 24px

            @media (max-width: $mobileScreenMaxWidth)
              font-size: 20px
              text-align: center

          .content
            display: flex
            flex-direction: column
            gap: 12px

            @media (min-width: $desktopScreenMinWidth)

            @media (max-width: $mobileScreenMaxWidth)
              align-items: center
              text-align: center

            > .title
              font-weight: 400
              line-height: 140%
              color: $blackViolet

              @media (min-width: $desktopScreenMinWidth)
                font-size: 20px

              @media (max-width: $mobileScreenMaxWidth)
                font-size: 18px

            .description
              display: flex
              gap: 6px
              align-items: center

              > .title
                font-weight: 400
                line-height: 140%
                font-size: 18px
                color: $yellow

          .mail
            > *
              font-weight: 500
              line-height: 140%
              color: $violet

              @media (min-width: $desktopScreenMinWidth)
                font-size: 20px

              @media (max-width: $mobileScreenMaxWidth)
                font-size: 18px

              &:hover
                text-decoration: underline 1px solid $violet

          .tel
            > *
              font-weight: 400
              line-height: 140%
              color: $blackViolet

              @media (min-width: $desktopScreenMinWidth)
                font-size: 20px

              @media (max-width: $mobileScreenMaxWidth)
                font-size: 18px

        .logo-container
          display: flex
          gap: 20px

          > *
            &:hover
              cursor: pointer

      .map
        overflow: hidden

        @media (min-width: $desktopScreenMinWidth)
          height: 444px
          aspect-ratio: calc(805 / 444)
          +border-radius(40px)

        @media (max-width: $mobileScreenMaxWidth)
          width: 100%
          aspect-ratio: calc(360 / 444)
          +border-radius(20px 20px 40px 40px)

        > *
          width: 100%
          height: 100%
</style>