<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: "kosmos-main-students",
})
</script>

<template>
  <div class="kosmos-main-students">
    <h2 class="text">
      Берём лучших своих учеников к&nbsp;себе в&nbsp;команду!
      <br><br>
      Предоставляем возможность официального трудоустройства!
    </h2>

<!--    <div class="sponsors-wrapper">-->
<!--      <img class="image" src="/assets/images/templates/main/students/beauty-city.png" alt="ГОРОД КРАСОТЫ">-->

<!--      <div class="bottom-group">-->
<!--        <img class="image" src="/assets/images/templates/main/students/beauty-point.svg" alt="ТОЧКА КРАСОТЫ">-->

<!--        <img class="image" src="/assets/images/templates/main/students/beauty-capital.png" alt="100ЛИЦА КРАСОТЫ">-->
<!--      </div>-->
<!--    </div>-->
  </div>
</template>

<style scoped lang="sass">
.kosmos-main-students
  background-color: $green
  display: flex

  @media (min-width: $desktopScreenMinWidth)
    font-size: 50px
    padding: 100px 144px 92px 112px
    gap: 152px

  @media (max-width: $mobileScreenMaxWidth)
    font-size: 24px
    padding: 70px 20px 60px
    flex-direction: column
    gap: 30px

  .text
    font-weight: 400
    line-height: 120%
    color: $blackViolet

    @media (min-width: $desktopScreenMinWidth)
      font-size: 50px
      text-align: center

    @media (max-width: $mobileScreenMaxWidth)
      font-size: 24px
      text-align: center

  .sponsors-wrapper
    display: flex
    flex-direction: column

    @media (min-width: $desktopScreenMinWidth)
      gap: 18px

    @media (max-width: $mobileScreenMaxWidth)
      gap: 10px
      align-items: center

    > .image
      background-color: $pink

      @media (min-width: $desktopScreenMinWidth)
        padding: 22px 31px 26px
        width: 371px
        height: 89px
        +border-radius(10px)

      @media (max-width: $mobileScreenMaxWidth)
        padding: 19px 27px 22px
        width: 320px
        height: 77px
        +border-radius(8.625px)

    .bottom-group
      display: flex

      @media (min-width: $desktopScreenMinWidth)
        gap: 54px
        align-items: flex-end

      @media (max-width: $mobileScreenMaxWidth)
        flex-direction: column-reverse
        gap: 10px
        align-items: center

      > .image:first-child
        background-color: $lightBlue
        +border-radius(10px)
        width: 201px
        height: 99px
        padding: 22px 29px 18px 30px

      > .image:last-child
        background-color: white
        +border-radius(10px)
        width: 120px
        height: 120px
        padding: 22px 19px 21px 18px
</style>