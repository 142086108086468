<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: "kosmos-main-team",

  data() {
    return {
      team: [
        // {
        //   name: ["Смирнова Татьяна", '#EFE7F5'],
        //   post: ["Косметолог-эстетист", '#BAA7D3'],
        //   merits: [
        //       ["Врач высшей категории", '#050A30', '#7AF266'],
        //       ["13 лет опыта", '#050A30', '#7AF266'],
        //   ],
        //   image: "Smirnova_Tatyana.png",
        //   backColor: "#1B0635",
        // },

        {
          name: ["Дмитрий Егорьев", '#EFE7F5'],
          post: ["Спортивный нутрициолог", '#BAA7D3'],
          merits: [
            ["Нутрициолог", '#050A30', '#7AF266'],
            ["5 лет опыта", '#050A30', '#7AF266'],
          ],
          image: "Dmitriy_Egoryev.jpg",
          backColor: "#1B0635",
        },

        {
          name: ["Сергей Коваленко", '#050A30'],
          post: ["Врач эндокринолог", '#616794'],
          merits: [
              ["Врач высшей категории", '#EFE7F5', '#59239D'],
              ["7 лет опыта", '#EFE7F5', '#59239D'],
          ],
          image: "Sergey_Kovalenko.jpg",
          backColor: "#7AF266",
        },

        // {
        //   name: ["Ефимова&nbsp;Елизавета", '#050A30'],
        //   post: ["Косметолог-инъекционист", '#616794'],
        //   merits: [
        //       ["Врач высшей категории", '#EFE7F5', '#59239D'],
        //       ["8 лет опыта", '#EFE7F5', '#59239D'],
        //   ],
        //   image: "Efimova_Elizaveta.png",
        //   backColor: "#7AF266",
        // },

        {
          name: ["Алина Захарова", '#EFE7F5'],
          post: ["Врач диетолог, нутрициолог", '#BAA7D3'],
          merits: [
              ["Врач высшей категории", '#050A30', '#7AF266'],
              ["5 лет опыта", '#050A30', '#7AF266'],
          ],
          image: "Alina_Zaharova.jpg",
          backColor: "#59239D",
        },
      ]
    }
  },

  methods: {
    getAdaptiveImageUrl(url, postfix) {
      return url.split('.').join('-' + postfix + '.')
    },
  },
})
</script>

<template>
  <div class="kosmos-main-team">
    <h2 class="title">Наша команда</h2>

    <div class="team-container">
      <div
          class="teammate"
          v-for="(teammate, index) in team"
          :key="`kosmos-main-team-container-${ index }`"
          :style="{
              backgroundColor: teammate.backColor
          }"
      >
        <div
            class="image only-ds"
            :style="{
                backgroundImage: `url('/assets/images/templates/main/team/${ getAdaptiveImageUrl(teammate.image, 'ds') }')`
            }"
        />

        <div
            class="image only-mb"
            :style="{
                backgroundImage: `url('/assets/images/templates/main/team/${ getAdaptiveImageUrl(teammate.image, 'mb') }')`
            }"
        />

        <div class="stats">
          <div class="info">
            <h2
                class="name"
                v-html="teammate.name[0]"
                :style="{
                    color: teammate.name[1]
                }"
            />

            <h1
                class="post"
                v-html="teammate.post[0]"
                :style="{
                    color: teammate.post[1]
                }"
            />
          </div>

          <template v-if="teammate.merits">
            <div class="merits-container" v-if="teammate.merits.length">
              <div
                  class="merit"
                  v-for="(merit, meritIndex) in teammate.merits"
                  :key="`kosmos-main-team-merits-${ index }-${ meritIndex }`"
                  :style="{
                    color: merit[1],
                    backgroundColor: merit[2]
                }"
              >
                <h1>{{ merit[0] }}</h1>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="sass">
.kosmos-main-team
  background-color: $newBlue
  display: flex
  flex-direction: column
  border: 2px solid $blackViolet

  @media (min-width: $desktopScreenMinWidth)
    gap: 60px
    padding: 100px 112px

  @media (max-width: $mobileScreenMaxWidth)
    gap: 40px
    padding: 70px 20px

  > .title
    font-weight: 700
    line-height: 120%
    text-align: center
    color: $white

    @media (min-width: $desktopScreenMinWidth)
      font-size: 40px

    @media (max-width: $mobileScreenMaxWidth)
      font-size: 24px

  .team-container
    display: flex

    @media (min-width: $desktopScreenMinWidth)
      gap: 20px
      flex-wrap: wrap
      justify-content: center

    @media (max-width: $mobileScreenMaxWidth)
      gap: 2px
      flex-direction: column

    .teammate
      display: flex
      flex-direction: column
      border: 1px solid #EFE7F580

      @media (min-width: $desktopScreenMinWidth)
        gap: 30px
        padding: 30px 30px 32px
        +border-radius(30px)
        width: calc((100% - 40px) / 3)

      @media (max-width: $mobileScreenMaxWidth)
        gap: 20px
        padding: 30px 20px
        +border-radius(30px)

      .image
        pointer-events: none
        user-select: none
        +background-image-settings()
        image-rendering: crisp-edges

        @media (min-width: $desktopScreenMinWidth)
          +border-radius(30px)
          width: 100%
          aspect-ratio: calc(332 / 370)

        @media (max-width: $mobileScreenMaxWidth)
          width: 100%
          aspect-ratio: 1
          +border-radius(10px)

      .stats
        display: flex
        flex-direction: column
        width: 100%
        align-items: flex-start

        @media (min-width: $desktopScreenMinWidth)
          gap: 20px

        @media (max-width: $mobileScreenMaxWidth)
          gap: 16px

        .info
          display: flex
          flex-direction: column
          gap: 12px

          .name
            font-weight: 700
            line-height: 120%
            color: $white

            @media (min-width: $desktopScreenMinWidth)
              font-size: 24px

            @media (max-width: $mobileScreenMaxWidth)
              font-size: 20px

          .post
            font-weight: 400
            line-height: 140%

            @media (min-width: $desktopScreenMinWidth)
              font-size: 18px

            @media (max-width: $mobileScreenMaxWidth)
              font-size: 16px

        .merits-container
          display: flex
          flex-direction: column
          gap: 10px
          align-items: flex-start

          .merit
            padding: 12px 20px 14px
            font-weight: 400
            line-height: 100%
            +border-radius(999px)

            @media (min-width: $desktopScreenMinWidth)
              font-size: 18px

            @media (max-width: $mobileScreenMaxWidth)
              font-size: 16px
</style>