<template>
  <div class="kosmos">
    <kosmos-header
        @openBurgerMenu="isBurgerMenuShown = true"
    />

    <kosmos-main />

    <kosmos-footer />

    <kosmos-burger-menu
        @close="isBurgerMenuShown = false"
        v-show="isBurgerMenuShown"
    />
  </div>
</template>

<script>
import kosmosHeader from "@/components/UI-kit/header/kosmos-header.vue";
import kosmosMain from "@/components/templates/main/kosmos-main.vue";
import kosmosFooter from "@/components/UI-kit/footer/kosmos-footer.vue";

import KosmosBurgerMenu from "@/components/UI-kit/burger-menu/kosmos-burger-menu.vue";

export default {
  name: 'App',

  components: {
    kosmosHeader,
    kosmosMain,
    kosmosFooter,

    KosmosBurgerMenu,
  },

  data() {
    return {
      isBurgerMenuShown: false,
    }
  },
}
</script>

<style scoped lang="sass">
.kosmos
  display: flex
  flex-direction: column
  align-items: center
  width: 100%
</style>
