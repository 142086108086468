<script>
import { defineComponent } from 'vue'

import kosmosButton from "@/components/UI-kit/button/kosmos-button.vue";

export default defineComponent({
  name: "kosmos-burger-menu",

  components: {
    kosmosButton,
  },
})
</script>

<template>
  <div class="kosmos-burger-menu">
    <nav>
      <a href="#about" @click="$emit('close')"><h2>О нас</h2></a>
      <a href="#courses" @click="$emit('close')"><h2>Курсы</h2></a>
      <a href="#team" @click="$emit('close')"><h2>Команда</h2></a>
<!--      <a href="#feedback" @click="$emit('close')"><h2>Отзывы</h2></a>-->
      <a href="#contacts" @click="$emit('close')"><h2>Контакты</h2></a>
    </nav>

    <h1 class="tel"><a href="tel:+79687006129">+7 (968) 700 61-29</a></h1>

    <kosmos-button title="Оставить заявку" type="light" />

    <svg class="close-button" xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 34 34" fill="none" @click="$emit('close')">
      <path d="M25.4872 8.48528L8.51667 25.4558M8.51667 8.48528L25.4872 25.4558" stroke="#EFE7F5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  </div>
</template>

<style scoped lang="sass">
.kosmos-burger-menu
  position: fixed
  width: 100%
  height: 100%
  background-color: rgba(47, 12, 92, 0.95)
  z-index: 100
  display: flex
  flex-direction: column
  padding: 110px 20px 0
  gap: 50px
  align-items: flex-end

  nav
    display: flex
    flex-direction: column
    gap: 30px
    align-items: flex-end
    text-align: end

    > *
      font-weight: 700
      line-height: 120%
      font-size: 20px
      color: $white

  .tel
    font-weight: 400
    line-height: 140%
    font-size: 24px
    color: $white

  .close-button
    display: flex
    width: 24px
    height: 24px
    align-items: center
    justify-content: center
    position: absolute
    right: 28px
    top: 50px

    &:hover
      cursor: pointer
</style>