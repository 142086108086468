<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: "kosmos-arrow",
})
</script>

<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="168" height="168" viewBox="0 0 168 168" fill="none">
    <path d="M138.398 72.5194C141.43 58.6389 127.96 43.6757 115.353 39.9996C98.9556 35.2131 81.2809 39.6363 65.5144 44.7452C49.5806 50.0614 34.2074 56.9293 19.6159 65.2501C16.816 66.8235 18.9791 71.1559 21.9373 70.2698C47.4007 62.6076 70.5464 48.4255 97.6408 46.6874C111.595 45.8004 133.306 51.3976 134.445 68.0303C135.557 84.1686 118.567 94.6194 108.731 104.226C107.807 105.131 108.883 107.016 110.146 106.483C122.887 100.647 135.412 86.287 138.398 72.5194Z" fill="#7AF266"/>
    <path d="M115.76 118.009C116.202 117.696 116.533 117.251 116.708 116.737C116.882 116.224 116.889 115.669 116.73 115.151C116.57 114.633 116.251 114.179 115.817 113.854C115.384 113.528 114.859 113.347 114.318 113.337C110.983 113.469 107.872 114.618 104.666 115.544C101.796 116.367 98.9011 116.992 95.9824 117.569C96.3706 109.433 99.2023 101.827 101.133 93.8876C101.7 91.567 98.4258 90.9348 97.6211 93.0684C94.2837 102.178 90.6102 111.391 90.144 121.194C90.169 121.971 90.5012 122.706 91.0677 123.238C91.6342 123.77 92.3886 124.055 93.1654 124.032C100.374 123.306 109.558 121.97 115.76 118.009Z" fill="#7AF266"/>
  </svg>
</template>

<style scoped lang="sass">

</style>