import { createApp } from 'vue'
import App from './App.vue'

import '/src/assets/styles/style.css'

import YmapPlugin from 'vue-yandex-maps'
import { ymapMarker } from "vue-yandex-maps";

const settings = {
  apiKey: 'cb42ebe3-f9f5-4c76-94e0-4ac8742524e1',
  lang: 'ru_RU',
  coordorder: 'latlong',
  enterprise: false,
  version: '2.1'
}

createApp(App)
  .use(YmapPlugin, settings)
  .use(ymapMarker)
  .mount('#app')
