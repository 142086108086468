<script>
import { defineComponent } from 'vue'

import kosmosLogo from "@/components/UI-kit/logos/kosmos-logo.vue";
import kosmosButton from "@/components/UI-kit/button/kosmos-button.vue";

export default defineComponent({
  name: "kosmos-footer",

  components: {
    kosmosLogo,
    kosmosButton,
  },
})
</script>

<template>
  <div class="kosmos-footer">
    <main>
      <div class="logo-wrapper">
        <kosmos-logo type="light" />

        <h1 class="title">Dr. KosMos</h1>
      </div>

      <nav>
        <a href="#about"><h2>О нас</h2></a>
        <a href="#courses"><h2>Курсы</h2></a>
        <a href="#team"><h2>Команда</h2></a>
<!--        <a href="#feedback"><h2>Отзывы</h2></a>-->
        <a href="#contacts"><h2>Контакты</h2></a>
      </nav>

      <div class="button-wrapper">
        <kosmos-button type="contrast" />
      </div>
    </main>

    <div class="other">
      <div class="author">
        <h1>Разработка сайта:</h1>

        <a href="https://samigrella.com/" target="_blank"><h1>samigrella.com</h1></a>
      </div>
    </div>
  </div>
</template>

<style scoped lang="sass">
.kosmos-footer
  background-color: $darkViolet
  display: flex
  max-width: 1440px
  width: 100%
  flex-direction: column
  gap: 40px

  @media (min-width: $desktopScreenMinWidth)
    padding: 80px 112px 100px
    +border-radius(70px 70px 0 0)
    margin-top: 10px

  @media (max-width: $mobileScreenMaxWidth)
    padding: 70px 20px
    +border-radius(40px 40px 0 0)
    margin-top: 5px

  main
    display: flex
    align-items: center

    @media (min-width: $desktopScreenMinWidth)
      justify-content: space-between

    @media (max-width: $mobileScreenMaxWidth)
      flex-direction: column
      gap: 30px

    .logo-wrapper
      display: flex
      flex-direction: column
      gap: 10px
      align-items: center

      > .title
        font-weight: 500
        font-size: 20px
        line-height: 120%
        color: $white

    nav
      display: flex
      gap: 30px
      align-items: center

      @media (min-width: $desktopScreenMinWidth)

      @media (max-width: $mobileScreenMaxWidth)
        flex-direction: column

      > *
        font-weight: 700
        font-size: 20px
        line-height: 120%
        color: $white

    .button-wrapper
      @media (min-width: $desktopScreenMinWidth)
        width: 350px

      @media (max-width: $mobileScreenMaxWidth)
        width: 100%

  .other
    display: flex

    @media (min-width: $desktopScreenMinWidth)
      gap: 58px
      align-items: baseline
      margin-left: 265px

    @media (max-width: $mobileScreenMaxWidth)
      flex-direction: column
      align-items: center
      gap: 20px

    .author
      display: flex
      align-items: baseline

      @media (min-width: $desktopScreenMinWidth)
        gap: 8px

      @media (max-width: $mobileScreenMaxWidth)
        gap: 4px

      > *
        font-weight: 400
        font-size: 16px
        line-height: 140%
        color: $gray

      a
        color: $green

        &:hover
          text-decoration: underline 1px solid $green

    .policy
      font-weight: 400
      font-size: 16px
      line-height: 140%
      color: $green

      &:hover
        text-decoration: underline 1px solid $green
        cursor: pointer
</style>