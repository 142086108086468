<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: "kosmos-video-play-button",
})
</script>

<template>
  <div class="kosmos-video-play-button" @click="$emit('click')">
    <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 100 100" fill="none" class="only-ds">
      <circle cx="50" cy="50" r="50" fill="#100457"/>
      <path d="M70 50L40 67.3205L40 32.6795L70 50Z" fill="#7AF266"/>
    </svg>

    <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60" fill="none" class="only-mb">
      <circle cx="30" cy="30" r="30" fill="#100457"/>
      <path d="M42 30L24 40.3923L24 19.6077L42 30Z" fill="#7AF266"/>
    </svg>
  </div>
</template>

<style scoped lang="sass">
.kosmos-video-play-button
  &:hover
    cursor: pointer

    circle
      fill: #2a206c
</style>