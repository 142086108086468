<script>
import { defineComponent } from 'vue'

import { openWhatsapp } from "@/components/functions/openWhatsapp/openWhatsapp";

export default defineComponent({
  name: "kosmos-button",

  props: {
    title: {
      type: String,
      default() {
        return "Оставить заявку"
      },
    },

    type: {
      type: String,
      default() {
        return "light"
      },
      validator(type) {
        return ['light', 'contrast', 'dark'].includes(type)
      }
    },
  },

  data() {
    return {
      openWhatsapp,
    }
  },

  computed: {
    buttonClasses() {
      const buttonClasses = {}
      buttonClasses[this.type] = true

      return buttonClasses
    },
  },
})
</script>

<template>
  <button
      class="kosmos-button"
      :class="buttonClasses"
      @click="openWhatsapp"
  >
    <h1 v-html="title" />
  </button>
</template>

<style scoped lang="sass">
.kosmos-button
  padding: 17px 20px 20px
  font-weight: 400
  font-size: 20px
  line-height: 120%
  +border-radius(999px)
  white-space: nowrap
  width: 100%
  user-select: none

  &:hover
    cursor: pointer

  &:active
    > *
      top: 1px

  &.light
    background-color: $green
    color: $blueViolet

    &:hover
      background-color: #9dfc8c

  &.contrast
    background-color: $green
    color: $blackViolet

    &:hover
      background-color: #9dfc8c

  &.dark
    background-color: $violet
    color: $white

    &:hover
      background-color: #753dbb

  > *
    position: relative
</style>