<script>
import { defineComponent } from 'vue'

import kosmosMainWelcome from "@/components/templates/main/block/kosmos-main-welcome.vue";
import kosmosMainAbout from "@/components/templates/main/block/kosmos-main-about.vue";
import kosmosMainStudents from "@/components/templates/main/block/kosmos-main-students.vue";
import kosmosMainPrograms from "@/components/templates/main/block/kosmos-main-programs.vue";
import kosmosMainTeam from "@/components/templates/main/block/kosmos-main-team.vue";
import kosmosMainReview from "@/components/templates/main/block/kosmos-main-review.vue";
import kosmosMainContacts from "@/components/templates/main/block/kosmos-main-contacts.vue";

export default defineComponent({
  name: "kosmos-main",

  components: {
    kosmosMainWelcome,
    kosmosMainAbout,
    kosmosMainStudents,
    kosmosMainPrograms,
    kosmosMainTeam,
    kosmosMainReview,
    kosmosMainContacts,
  },
})
</script>

<template>
  <div class="kosmos-main">
    <kosmos-main-welcome />

    <kosmos-main-about id="about" />

    <kosmos-main-students />

    <kosmos-main-programs id="courses" />

    <kosmos-main-team id="team" />

    <kosmos-main-review id="feedback" v-if="false"/>

    <kosmos-main-contacts id="contacts" />
  </div>
</template>

<style scoped lang="sass">
.kosmos-main
  display: flex
  flex-direction: column
  width: 100%

  @media (min-width: $desktopScreenMinWidth)
    max-width: 1440px
    gap: 10px

  @media (max-width: $mobileScreenMaxWidth)
    gap: 5px

  > *
    @media (min-width: $desktopScreenMinWidth)
      +border-radius(70px)

    @media (max-width: $mobileScreenMaxWidth)
      +border-radius(40px)

      &:nth-child(1), &:nth-child(2)
        +border-radius(30px)
</style>