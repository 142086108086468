<script>
import { defineComponent } from 'vue'

import kosmosVideoPlayButton from "@/components/UI-kit/video-UI/kosmos-video-play-button.vue";

export default defineComponent({
  name: "kosmos-main-review",

  components: {
    kosmosVideoPlayButton,
  },

  data() {
    return {
      reviews: [
        {
          name: ['Екатерина', '#EFE7F5'],
          description: [
            {
              title: ['Курс:', '#A5C5DA'],
              text: ['Нутрициология', '#7AF266'],
            },
          ],
          backColor: '#1B0635',
          src: 'Ekaterina.png',
        },

        {
          name: ['Татьяна', '#EFE7F5'],
          description: [
            {
              title: ['Курс:', '#A5C5DA'],
              text: ['Косметология без медицинского образования', '#7AF266'],
            },
          ],
          backColor: '#59239D',
          src: 'Tatyana.png',
        },

        {
          name: ['Валерия', '#144361'],
          description: [
            {
              title: ['Курс:', '#5D849D'],
              text: ['Нутрициология', '#59239D'],
            },
          ],
          backColor: '#7AF266',
          src: 'Valeria.png',
        },
      ],
    }
  },

  methods: {
    getAdaptiveImageUrl(url, postfix) {
      return url.split('.').join('-' + postfix + '.')
    },
  },
})
</script>

<template>
  <div class="kosmos-main-review">
    <h2 class="title">Отзывы</h2>

    <div class="reviews-container">
      <div
          class="review"
          v-for="(review, index) in reviews"
          :key="`kosmos-main-review-${ index }`"
          :style="{
              backgroundColor: review.backColor
          }"
      >
        <div
            class="image only-ds"
            :style="{
                backgroundImage: `url(/assets/images/templates/main/review/${ getAdaptiveImageUrl(review.src, 'ds') })`
            }"
        >
          <kosmos-video-play-button />
        </div>

        <div
            class="image only-mb"
            :style="{
                backgroundImage: `url(/assets/images/templates/main/review/${ getAdaptiveImageUrl(review.src, 'mb') })`
            }"
        >
          <kosmos-video-play-button />
        </div>

        <div class="info">
          <h2
              class="name"
              v-html="review.name[0]"
              :style="{
                  color: review.name[1]
              }"
          />

          <div
              class="stat"
              v-for="(stat, statIndex) in review.description"
              :key="`kosmos-main-review-description-${ index }-${ statIndex }`"
          >
            <h1
                class="title"
                v-html="stat.title[0]"
                :style="{
                    color: stat.title[1]
                }"
            />

            <h1
                class="value"
                v-html="stat.text[0]"
                :style="{
                    color: stat.text[1]
                }"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="sass">
.kosmos-main-review
  display: flex
  flex-direction: column
  align-items: center
  background-color: $blue

  @media (min-width: $desktopScreenMinWidth)
    gap: 60px
    padding: 100px 112px

  @media (max-width: $mobileScreenMaxWidth)
    gap: 40px
    padding: 70px 20px

  > .title
    font-weight: 700
    line-height: 120%
    color: $white

    @media (min-width: $desktopScreenMinWidth)
      font-size: 40px

    @media (max-width: $mobileScreenMaxWidth)
      font-size: 24px

  .reviews-container
    display: flex
    max-width: 100%

    @media (min-width: $desktopScreenMinWidth)
      gap: 20px

    @media (max-width: $mobileScreenMaxWidth)
      gap: 2px
      flex-direction: column

    .review
      display: flex
      flex-direction: column
      gap: 20px

      @media (min-width: $desktopScreenMinWidth)
        padding: 30px
        +border-radius(40px)
        max-width: calc((100% - 20px * 2) / 3)

      @media (max-width: $mobileScreenMaxWidth)
        padding: 30px 20px
        +border-radius(30px)

      .image
        +background-image-settings()
        display: flex
        align-items: center
        justify-content: center

        @media (min-width: $desktopScreenMinWidth)
          width: 332px
          height: 370px
          +border-radius(20px)

        @media (max-width: $mobileScreenMaxWidth)
          width: 100%
          aspect-ratio: 1
          +border-radius(10px)

      .info
        display: flex
        flex-direction: column
        gap: 12px

        .name
          font-weight: 700
          line-height: 120%

          @media (min-width: $desktopScreenMinWidth)
            font-size: 24px

          @media (max-width: $mobileScreenMaxWidth)
            font-size: 20px

        .stat
          display: flex
          gap: 4px
          align-items: baseline
          font-weight: 400
          line-height: 140%
          overflow: hidden
          text-overflow: ellipsis
          flex: 1

          @media (min-width: $desktopScreenMinWidth)
            font-size: 18px

          @media (max-width: $mobileScreenMaxWidth)
            font-size: 16px

          > *:last-child
            white-space: nowrap
            text-overflow: ellipsis
            overflow: hidden
</style>