<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: "kosmos-star",
})
</script>

<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 35 35" fill="none">
    <path d="M17.5 0L22.2266 12.7734L35 17.5L22.2266 22.2266L17.5 35L12.7734 22.2266L0 17.5L12.7734 12.7734L17.5 0Z" fill="#7AF266"/>
  </svg>
</template>

<style scoped lang="sass">

</style>