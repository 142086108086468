<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: "kosmos-main-about",

  data() {
    return {
      isVideoPlaying: false,
    }
  },
})
</script>

<template>
  <div class="kosmos-main-about">
    <h2 class="title">Немного о Dr.Kosmos</h2>

    <main>
      <div class="stats-wrapper">
        <div class="stat">
          <h1 class="title">Работаем более</h1>

          <div class="value">
            <h2>3х</h2>

            <h2>лет</h2>
          </div>
        </div>

        <div class="stat">
          <h1 class="title">Выпустили</h1>

          <div class="value">
            <h2>>1000</h2>

            <h2>учеников</h2>
          </div>
        </div>

        <div class="stat">
          <h1 class="title">Трудоустроили</h1>

          <div class="value">
            <h2>>300</h2>

            <h2>выпускников</h2>
          </div>
        </div>
      </div>

      <div class="text">
        <h1>Мы не просто готовим специалистов узкого профиля, а даëм возможность после обучения работать с нами.
          Все, кто освоит материал имеют шанс стать частью команды и зарабатывать хорошие деньги честным трудом.
          Весь материал обучения опубликован в закрытом ТГ канале к которому ученики имеют неограниченный доступ.
          Также постоянно добавляются новые уроки для повышения качества специалистов,
          а кураторы готовы помочь и ответить на возникающие вопросы в любое время.</h1>

        <br>

        <h1>Хочешь монетизировать свои знания в самой прекрасной индустрии, делая тела и жизни других лучше? Тогда мы тебя ждëм!</h1>
      </div>

<!--      <div class="video-wrapper">-->
<!--        <video controls poster="/assets/videos/about.jpg" ref="video" @play="isVideoPlaying = true" @pause="isVideoPlaying = false">-->
<!--          <source src="/assets/videos/about.mp4">-->
<!--        </video>-->

<!--        <kosmos-video-play-button class="only-ds" style="z-index: 1000" @click="this.$refs.video.play()" v-show="!isVideoPlaying"/>-->
<!--      </div>-->
    </main>
  </div>
</template>

<style scoped lang="sass">
.kosmos-main-about
  display: flex
  flex-direction: column
  align-items: center
  background-color: $newBlue
  border: 2px solid $blueViolet

  @media (min-width: $desktopScreenMinWidth)
    gap: 80px
    padding: 100px 112px

  @media (max-width: $mobileScreenMaxWidth)
    gap: 40px
    padding: 70px 20px

  > .title
    font-weight: 400
    line-height: 120%
    color: $white

    @media (min-width: $desktopScreenMinWidth)
      font-size: 50px

    @media (max-width: $mobileScreenMaxWidth)
      font-size: 24px

  main
    display: flex
    width: 100%

    @media (min-width: $desktopScreenMinWidth)
      gap: 40px
      align-items: center
      justify-content: space-around

    @media (max-width: $mobileScreenMaxWidth)
      flex-direction: column
      gap: 10px

    .stats-wrapper
      display: flex
      flex-direction: column
      background-color: $darkViolet
      width: 100%
      flex: 1
      border: 1px solid #EFE7F580

      @media (min-width: $desktopScreenMinWidth)
        gap: 20px
        padding: 30px 50px
        +border-radius(40px)
        max-width: 480px

      @media (max-width: $mobileScreenMaxWidth)
        gap: 16px
        padding: 40px 50px
        +border-radius(20px)

      .stat
        display: flex
        flex-direction: column
        text-align: center

        > .title
          font-weight: 400
          font-size: 20px
          line-height: 140%
          color: $white

        .value
          color: $green
          display: flex
          gap: 7px
          align-items: baseline
          justify-content: center

          > *:first-child
            font-weight: 600
            line-height: 140%

            @media (min-width: $desktopScreenMinWidth)
              font-size: 50px

            @media (max-width: $mobileScreenMaxWidth)
              font-size: 36px

          > *:last-child
            font-weight: 400
            line-height: 140%

            @media (min-width: $desktopScreenMinWidth)
              font-size: 30px

            @media (max-width: $mobileScreenMaxWidth)
              font-size: 24px

    .text
      color: $white
      line-height: 140%
      text-align: center

      @media (min-width: $desktopScreenMinWidth)
        font-size: 22px

      @media (max-width: $mobileScreenMaxWidth)
        padding: 50px
        font-size: 18px

    .video-wrapper
      display: flex
      align-items: center
      justify-content: center
      overflow: hidden

      @media (min-width: $desktopScreenMinWidth)
        +border-radius(40px)

      @media (max-width: $mobileScreenMaxWidth)
        +border-radius(20px)

      > *
        position: absolute

      video
        aspect-ratio: calc(720 / 1280)
        position: unset

        @media (min-width: $desktopScreenMinWidth)
          width: 400px

        @media (max-width: $mobileScreenMaxWidth)
          width: 100%
</style>