<script>
import { defineComponent } from 'vue'

import kosmosStar from "@/components/UI-kit/logos/kosmos-star.vue";
import kosmosArrow from "@/components/UI-kit/arrow/kosmos-arrow.vue";

import kosmosButton from "@/components/UI-kit/button/kosmos-button.vue";

export default defineComponent({
  name: "kosmos-main-welcome",

  components: {
    kosmosStar,
    kosmosArrow,

    kosmosButton,
  },
})
</script>

<template>
  <div class="kosmos-main-welcome">
    <div class="title">
      <div class="figure only-ds">
        <kosmos-star class="big-star" />

        <kosmos-star class="small-star" />
      </div>

      <h2 class="text">Обучаем <span class="green">спортивной нутрициологии</span> в&nbsp;нашей школе Доктор&nbsp;Космос</h2>

      <kosmos-arrow class="arrow only-ds" />

      <kosmos-star class="big-star only-mb" />

      <kosmos-star class="small-star only-mb" />
    </div>

    <main>
      <h1 class="text">Наша программа для&nbsp;тех, кто хочет
        начать свою карьеру в&nbsp;области телостроения и&nbsp;здоровья,
        получить новые знания и&nbsp;навыки, а&nbsp;также расширить
        свои возможности в&nbsp;профессиональном плане</h1>

      <div class="button-wrapper">
        <kosmos-button class="button" title="Записаться на обучение" />

        <kosmos-star class="big-star only-ds" />

        <kosmos-star class="small-star only-ds" />
      </div>
    </main>
  </div>
</template>

<style scoped lang="sass">
.kosmos-main-welcome
  background-color: $violet
  display: flex
  flex-direction: column

  @media (min-width: $desktopScreenMinWidth)
    padding: 100px 112px
    gap: 50px

  @media (max-width: $mobileScreenMaxWidth)
    padding: 50px 20px 70px
    gap: 30px

  > .title
    color: $white
    font-weight: 400
    line-height: 120%
    position: relative

    @media (min-width: $desktopScreenMinWidth)
      font-size: 75px

    @media (max-width: $mobileScreenMaxWidth)
      font-size: 29px
      text-align: center
      text-shadow: 0 0 4px $violet

    > .big-star
      width: 35px
      height: 35px
      position: absolute
      top: calc(100% - 35px)
      left: 0
      z-index: 5

    > .small-star
      width: 23px
      height: 23px
      position: absolute
      top: calc((100% - 23px) / 105 * 70)
      right: 0
      z-index: 5

    .figure
      display: inline-block
      width: 474px
      height: 61px
      +border-radius(999px)
      border: 5px solid $white
      margin-right: -64px
      position: relative
      top: 1px

      > .big-star
        position: absolute
        width: 35px
        height: 35px
        top: 24px
        left: 24px

      > .small-star
        position: absolute
        width: 23px
        height: 23px
        top: 1px
        right: 113px

    .text
      position: relative
      display: inline
      z-index: 100

      @media (min-width: $desktopScreenMinWidth)

      @media (max-width: $mobileScreenMaxWidth)
        text-align: center


    .arrow
      position: absolute
      margin-left: 30px
      margin-top: -20px

  main
    display: flex

    @media (min-width: $desktopScreenMinWidth)
      gap: 100px
      align-items: center


    @media (max-width: $mobileScreenMaxWidth)
      flex-direction: column
      gap: 20px

    .text
      font-weight: 400
      line-height: 140%
      color: $white
      flex: 1

      @media (min-width: $desktopScreenMinWidth)
        font-size: 20px

      @media (max-width: $mobileScreenMaxWidth)
        font-size: 18px
        text-align: center

    .button-wrapper
      position: relative

      @media (min-width: $desktopScreenMinWidth)
        width: 384px

      @media (max-width: $mobileScreenMaxWidth)

      button
        @media (min-width: $desktopScreenMinWidth)
          transform: rotate(-4deg)

        @media (max-width: $mobileScreenMaxWidth)

      > .big-star
        width: 35px
        height: 35px
        right: 56px
        top: 52px
        position: absolute

      > .small-star
        width: 20px
        height: 20px
        left: 57px
        top: -13px
        position: absolute
</style>